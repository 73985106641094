.home-container {
    position: relative;
    height: calc(100vh - 80px);
}

.inner-hero {
    position: absolute;
    top: 45%;
    width: 100%;
    text-align: center;
}

.inner-hero h1 {
    color: #fafafa;
    font-family: 'Freight', sans-serif;
    font-size: 2rem;
    letter-spacing: 2px;
    padding-inline: 2rem;
}

#hero-image {
    height: 100%;
    object-fit: cover;
    object-position: 0 -10px;
    width: 100%;
    filter: brightness(65%);
}

#introduction #title {
    margin-bottom: 1rem;
}

#introduction #content {
    max-width: 70ch;
}

#information #content {
    max-width: 70ch;
}

#information #title {
    margin-bottom: 1rem;
}

#info-steps li {
    margin-top: 1rem;
}

@media screen and (max-width: 960px) {
    .home-container {
        height: 100%;

    }

    #hero-image {
        height: 40%;
    }

    .inner-hero h1 {
        font-size: 1.25rem;
    }
}