.container {
    max-width: 1000px;
    margin: 0 auto;
    padding-inline: 1rem;
}

.card {
    margin: 2rem;
    border-radius: 10px;
    padding: 0px;
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 3rem;
}

.imagine-membru {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.249);
}
.nume-membru {
    font-size: 1.5rem;
    margin-bottom: 0px;
}

.pozitie-membru {
    font-size: 1.25rem;
    margin-bottom: 10px;
}

.card-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
}


@media (max-width: 768px) {
    .titlu {
        font-size: 20px;
    }

    .paragraph {
        font-size: 16px;
    }
    .imagine-membru {
        width: 80px;
        height: 80px;
    }
    .nume-membru {
        font-size: 1.15rem;
        margin-bottom: 0px;
    }
    
    .pozitie-membru {
        font-size: 1rem;
        margin-bottom: 10px;
    }
    .card {
        margin-inline: 0;
        gap: 1rem;
    }
    
}

