.navbar {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 80px;
    background-color: #f2f2f2;
}

.logo {
    width: 70px;
    height:80px;
}

.hamburger {
    display: none;
}

.hamburger span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #000;
    margin-bottom: 4px;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.navbar-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav-item {
    margin-right: 20px;
}

.nav-link {
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    padding: 0 15px;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.25rem;
    color: #3a3a3a;
    font-weight: 600;
}

.nav-link:after {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 15%;
    background-color: #CEB537;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
}

.nav-link:hover:after {
    transform: scaleX(1);
}


/* Media Query for mobile devices */
@media (max-width: 767px) {
    .navbar {
        flex-wrap: wrap;
    }

    .navbar-brand {
        display: flex;
        align-items: center;
        flex-basis: 100%;
        margin-bottom: 10px;
    }

    .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        margin-right: 10px;
        width: 30px;
        height: 27px;
        padding: 20px;
    }

    .hamburger span {
        width: 100%;
    }

    .navbar-menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 20px 0;
        background-color: #f2f2f2;
        z-index: 100;
    }

    .navbar.open .navbar-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nav-item {
        margin: 10px 0;
    }
}