#lower-footer {
    height: 40px;
    background-color: #CEB537;
    max-width: 100%;
}

#footer-description {
    text-align: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
    margin: 20px 10px;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.footer-title {
    font-size: 1.25rem;
    font-weight: bold;
}

.footer-item {
    font-size: 1.25rem;
    line-height: 1.5;
    color: #666;
    text-justify: distribute-all-lines;
}

.footer-item a {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

#credits {
    padding: 20px;
}

.footer-link {
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    padding: 0 5px;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.25rem;
}

.footer-link:after {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 15%;
    background-color: #CEB537;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
}

.footer-link:hover:after {
    transform: scaleX(1);
}


@media (max-width: 700px) {
    .flex-row {
        flex-direction: column;
        align-items: center;
    }

    .flex-column {
        align-items: center;
        margin: 10px 0px;
    }

    .footer-item,
    .footer-item a {
        font-size: 1rem;
    }

    .footer-title {
        font-size: 1.25rem;
    }
}