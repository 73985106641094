.partners-container {
    display: flex;
    position: relative;
    flex-direction: row;
    
}

.half-screen {
    position: relative;
    height: 100vh;
    flex: 1;
    flex-basis: 1;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    object-fit: cover;
}

.half-screen:nth-child(1) {
    border-right: solid 1px black;
}
.half-screen:nth-child(2) {
    border-left: solid 1px black;

}

.half-screen img {
    z-index: 1;
    width: 50%;
    filter: brightness(100%);
    transition: filter 0.25s ease-in-out;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100vh;
    transition: background-color 0.25s ease-in-out;
    background-color: rgba(0, 0, 0, 0.25);
}
.overlay:hover {
    cursor: pointer;
    background-color: unset;
}

.overlay:hover + img {
    filter: brightness(110%);
}

@media screen and (max-width: 768px) {
    .partners-container {
        flex-direction: column;
    }
    .half-screen {
        width: 100%;
        height: 50vh;
        flex: none;
    }
    .half-screen:nth-child(1) {
        border-bottom: solid 1px black;
    }
    .half-screen:nth-child(2) {
        border-top: solid 1px black;
    }
    .overlay {
        width: 100%;
        height: 100%;
    }
}