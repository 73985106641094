* {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
    text-decoration: none;
    color: #000;
    font-family: 'Freight', sans-serif;
}

img {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
}

body {
    background-color: #f0f0f0;
}

a {
    font-size: 17px;
}

.text-block {
    margin-block: 2rem;
    font-size: 1.25rem;
    background-color: #fafafa;
    padding: 4rem;
    letter-spacing: 1px;
    line-height: 1.70;
    border-left-width: 1px;
    border-left-color: #CEB537;
    border-left-style: solid;
}

.text-block p,
.text-block h1,
.text-block li {
    color: #3a3a3a;
}

.fade-in {
    opacity: 0;
    animation: fade-in-animation 0.5s ease-in-out forwards;
}

.footer {
    flex-shrink: 0;
}

@keyframes fade-in-animation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

html,
body {
    height: 100%;
    margin: 0;
    scroll-behavior: smooth;
}

@font-face {
    font-family: freight;
    src: url(/public/FreightTextProBook-Regular.ttf);
    font-display: swap;
}

@media screen and (max-width: 768px) {
    .text-block {
        padding: 1rem;
        font-size: 16px;
    }
}