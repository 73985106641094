.title {
    padding: 20px;
}

.gallery {
    margin: 0px 0px;
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    grid-gap: 20px;
    padding-bottom: 5rem;
}

.gallery-title {
    padding: 2rem 0rem;
}

.gallery img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

}

@media screen and (max-width: 1200px) {
    .gallery {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        width: 85%;
    }
}

@media screen and (max-width: 650px) {
    .gallery {
        grid-template-columns: repeat(1, minmax(200px, 1fr));
        width: 90%;
    }
}